import React, { useState, useContext, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import { FiX } from "react-icons/fi"
import { CartContext } from "../cart"
import { SEO } from '../components/seo';

import { useIsMobile } from "../utils"
import { numberWithCommas } from '../utils';

const CartItem = ({
  item,
  onRemove,
  onCountChange,
}) => {
  const { isMobile } = useIsMobile()

  const RemoveButton = () => (
    <div
      style={{
        width: 16,
      }}
    >
      <FiX
        style={{
          color: "red",
          cursor: "pointer",
        }}
        onClick={() => {
          onRemove(item.id)
        }}
      ></FiX>
    </div>
  );

  const ItemImage = () => (
    <div
      style={{
        marginRight: 24,
      }}
      onClick={() => {
        navigate(item.url)
      }}
    >
      <img
        style={{
          width: 80,
          height: 80,
          cursor: "pointer",
          borderRadius: 8,
        }}
        src={item.imageUrl}
      />
    </div>
  )

  const ItemName = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <div
        className="body2"
        onClick={() => {
          navigate(item.url)
        }}
        style={{
          cursor: "pointer",
          fontSize: 14,
        }}
      >
        {item.title} - {item.spec}
      </div>
      {item.engName && <div className="body1">{item.engName}</div>}
      {item.customAttributes.length > 0 && (
        <div>
          {item.customAttributes
            .map(e => `${e.key}: ${e.value}`)
            .join(",")}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div></div>
      </div>
    </div>
  );

  const UnitPrice = () => (
    <div
      style={{
        ...(!isMobile && {
          width: 80,
          flexShrink: 0,
          textAlign: "center",
        })
      }}
    >
      {`\$${numberWithCommas(item.price / item.count)}`}
    </div>
  );

  const Price = () => (
    <div
      style={{
        flexshrink: 0,
        textalign: isMobile ? "end" : "center",
        ...isMobile ? {
        } : {
          width: "16.7%",
          textAlign: "center",
        },
      }}
    >
      {`\$${numberWithCommas(item.price)}`}
    </div>
  )

  if (isMobile) {
    return (
      <div
        style={{
          margin: "16px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          width: "100%",
          rowGap: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ItemImage />
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <ItemName />
          </div>
          <div
            style={{
              width: 64,
              display: "flex",
              justifyContent: "end",
              flexShrink: 0,
            }}
          >
            <RemoveButton />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: 'end',
            width: "100%",
            gap: 16,
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <input
              style={{ width: "80px" }}
              type="number"
              min="1"
              value={item.count}
              onChange={({ target }) => {
                onCountChange(target.value)
              }}
            />
          </div>
          <div
            style={{ 
              width: 48, 
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Price />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: "16px 0px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        rowGap: "2px",
        position: "relative",
      }}
    >
      <div style={{
        position: 'absolute',
        right: 8,
        ...isMobile && { top: -8 },
      }}>
        <RemoveButton />
      </div>
      <ItemImage />
      <ItemName />
      <UnitPrice />
      <div
        style={{
          width: "16.7%",
          textAlign: "center",
          flexShrink: 0,
        }}
      >
        <input
          style={{ width: "80px" }}
          type="number"
          min="1"
          value={item.count}
          onChange={({ target }) => {
            onCountChange(target.value)
          }}
        />
      </div>
      <Price />
    </div>
  );
}


const Cart = () => {
  const { isMobile } = useIsMobile()
  const { checkout, updateCheckoutNote, removeItem, checkoutId, initialized, updateItems, vacation } = useContext(
    CartContext
  )
  const [items, setItems] = useState([])
  const [note, setNote] = useState('');
  const [syncTimer, setSyncTimer] = useState(null);
  const itemsRef = useRef(items);
  itemsRef.current = items;

  const onItemCountChange = () => {
    updateItems(itemsRef.current.map(i => ({id: i.id, quantity: parseInt(i.count)})));
  }

  useEffect(() => {
    setItems(checkout?.items || [])
    if (checkout?.note && !note) {
      setNote(
        checkout?.note
      )
    }
  }, [checkout]);


  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        className="title3"
        style={{
          marginBottom: "8px",
          marginTop: "40px",
        }}
      >
        Your Cart
      </div>
      <div className="title2">購物車</div>
      <div
        style={{
          display: "flex",
          maxWidth: 1200,
          width: "100%",
          margin: "0px auto",
          justifyContent: "center",
          padding: 24,
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderBottom: '1px solid #000',
          }}
        >
          {!initialized && (
            <div>
              <h5>購物車載入中 ...</h5>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  location.reload()
                }}
              >
                重新整理
              </a>
            </div>
          )}
          {(items.length < 1 || !checkout) && initialized && (
            <div>
              <h5>購物車中沒有商品</h5>
            </div>
          )}
          {
            !isMobile && (
              <div
                style={{
                  display: 'flex',
                  borderBottom: '1px solid #000',
                  paddingBottom: '8px'
                }}
              >
                <div
                  style={{ flex: 1 }}
                >
                  商品
                </div>
                <div
                  style={{ width: 80, textAlign: 'center' }}
                >
                  價格
                </div>
                <div
                  style={{ width: '16.7%', textAlign: 'center' }}
                >
                  數量
                </div>
                <div
                  style={{ width: '16.7%', textAlign: 'center' }}
                >
                  小計
                </div>
              </div>
            )
          }
          {items.map(e => (
            <CartItem
              item={e}
              onRemove={removeItem}
              onCountChange={(value) => {
                setItems(items.map(i => {
                  if (i.id === e.id) {
                    return {
                      ...i,
                      count: value,
                      price: e.price / e.count * parseInt(value),
                    }
                  }
                  return i
                }))
                if (syncTimer) {
                  clearTimeout(syncTimer);
                }
                setSyncTimer(setTimeout(() => {
                  onItemCountChange();
                }, 1000))
              }}
            />
          ))}
        </div>
        <div style={{ textAlign: 'end', width: '100%' }}>
          <h5 style={{ marginTop: 16 }}>總計 ${numberWithCommas(checkout?.subtotalPrice || 0)}</h5>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: 'space-between',
            width: '100%',
            marginTop: '16px',
            flexWrap: "wrap",
            rowGap: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              ...!isMobile && { marginTop: -48, flex: 1 },
              ...isMobile && { width: '100%' },
            }}
          >
            <span>告訴我們你的特殊需求</span>
            <textarea
              value={note}
              onChange={({ target }) => {
                setNote(target.value)
              }}
              style={{
                height: '60px',
                padding: '2px 6px',
                ...isMobile && { width: '100%' },
              }}
            />
          </div>

          { isMobile && <div style={{ flexBasis: "100%", height: 0 }} /> }
          <div
            style={{
              display: "flex",
              flex: 1,
              ...isMobile && { flexDirection: "column" },
              width: 'fit-content',
              gap: '16px',
              justifyContent: 'end'
            }}
          >
            <div
              className="my-btn"
              style={{
                border: '1px solid #000',
                padding: '8px 24px',
                textAlign: 'center',
                cursor: 'pointer',
                height: 'fit-content',
                borderRadius: 4,
              }}
              onClick={() => {
                navigate("/shop")
              }}
            >
              繼續購物
            </div>
            {checkout?.subtotalPrice > 0 && items.length > 0 && (
                <div
                  className="my-btn"
                  style={{
                    border: '1px solid #000',
                    padding: '8px 24px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    height: 'fit-content',
                    borderRadius: 4,
                  }}
                  onClick={async () => {
                    if (vacation) {
                      alert(vacation);
                      return;
                    }
                    if (note) {
                      await updateCheckoutNote(note);
                    }
                    navigate("/checkout")
                  }}
                >
                  結帳
                </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
export const Head = () => (
  <SEO
    title="購物車"
  />
)
